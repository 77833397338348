<template>
    <div class="chart-config-container">
      <div class="man-title">
        <span>数据模型</span>
        <div>
          <!-- <el-button @click="edit" class="btn" type="primary" size="mini">编辑模型图表</el-button> -->
          <!-- <el-button @click="dlt" class="btn" type="danger" size="mini">删除模型图表</el-button> -->
        </div>
      </div>
      <div class="table-tree">
        <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.4)">
          <div class="tree-title">
            <div>
              <i class="iconfont icon-peizhi" style="margin-right:10px;"></i>数据模型分类
            </div>
            <div>
              <el-button class="chart-btn" type="primary" size="small" @click="addConfigProject">新增数据模型</el-button>
            </div>
          </div>
          <div v-if="configProject.length>0" class="tree-wrapper">
            <div class="tree-park" v-for="(item,index) in configProject" :key="index">
              <div class="park-title" :class="current==item.itemId?'bright':''" @click.prevent="configToggle(item.itemId)">
                <div>
                  <!-- <span class="class-icon iconfont icon-shujufenxi1" :style="current==item.itemId?'color:#FFF;':'color:#202020;'"></span> -->
                  <span style="margin-right: 15px;">{{item.item}}</span>
                </div>
                <div>
                  <el-dropdown placement="bottom-start" trigger="click">
                    <span @click.stop="">
                      <span class="el-dropdown-link">
                        <i class="el-icon-more operate" :style="current==item.itemId?'color:#2B82D9;':'color:#202020;'"></i>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="editConfigProject(item)">修改</div></el-dropdown-item>
                      <el-dropdown-item :disabled="roleId == 2 || roleId == 3 || roleId == 4"><div @click="dltConfigProject(item)">删除</div></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
        </div>
        <div style="width:100%;" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.4)">
          <div style="text-align: right;margin: 15px 0;">
            <el-button @click="add" class="chart-btn" type="primary" size="small" :disabled="configProject.length==0">添加模型图表</el-button>
          </div>
          <div class="chart-size" v-loading="chartListLoading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.7)">
            <!-- 指定分类下存在图表列表 -->
            <div v-if="graphList.length>0">
              <!-- 默认为卡片列表 -->
              <div class="chart-cover" v-if="!isCardList">
                <div class="chart-cover-item" v-for="item in graphList" :key="item.graphId">
                  <div>
                    <legend-box1 :chartName="item.graphId" v-if="item.graphCode=='bar-realtime-1'"/>
                    <legend-box15 :chartName="item.graphId" v-if="item.graphCode=='bar-realtime-2'"/>

                    <legend-box2 :chartName="item.graphId" v-if="item.graphCode=='bar-total-1'"/>
                    <legend-box3 :chartName="item.graphId" v-if="item.graphCode=='bar-contrast-1'"/>
                    <legend-box5 :chartName="item.graphId" v-if="item.graphCode=='bar-contrast-2'"/>
                    <legend-box6 :chartName="item.graphId" v-if="item.graphCode=='distribution-trend-1'"/>
                    <legend-box7 :chartName="item.graphId" v-if="item.graphCode=='distribution-realtime-1'"/>
                    <legend-box8 :chartName="item.graphId" v-if="item.graphCode=='distribution-realtime-2'||item.graphCode=='distribution-trend-2'"/>
                    <legend-box11 :chartName="item.graphId" v-if="item.graphCode=='distribution-contrast-1'"/>
                    <legend-box12 :chartName="item.graphId" v-if="item.graphCode=='pie-chart-total-1'"/>
                    <legend-box16 :chartName="item.graphId" v-if="item.graphCode=='distribution-merge-trend-1'"/>
                    <div class="chart-top-title">
                      <div class="chart-top-title-shape-wrap">
                        <div class="chart-top-title-shape-left">{{ item.graphName }}</div>
                        <div class="chart-top-title-shape-right">
                          <span class="chart-top-title-type" :style="item.statisticsDimension=='realtime'&&'background-color:#27ae60;'" v-if="item.statisticsDimension=='realtime'">实时</span>
                          <span class="chart-top-title-type" :style="item.statisticsDimension=='total'&&'background-color:#409EFF;'" v-if="item.statisticsDimension=='total'">
                            {{ item.statisticsType=='daily'?'单日累计':
                            item.statisticsType=='weekly'?'7日累计':
                            item.statisticsType=='monthly'?'30日累计':'累计' }} 
                          </span>
                          <span class="chart-top-title-type" :style="item.statisticsDimension=='trend'&&'background-color:#f39c12;'" v-if="item.statisticsDimension=='trend'">
                            {{ item.statisticsType=='daily'?'单日趋势':
                            item.statisticsType=='weekly'?'7日趋势':
                            item.statisticsType=='monthly'?'30日趋势':'趋势' }} 
                          </span>
                          <span class="chart-top-title-type" :style="item.statisticsDimension=='contrast'&&'background-color:#e74c3c;'" v-if="item.statisticsDimension=='contrast'">
                            {{ item.statisticsType=='daily'?'单日对比':
                            item.statisticsType=='weekly'?'7日对比':
                            item.statisticsType=='monthly'?'30日对比':'对比' }} 
                          </span>
                          <span class="chart-top-title-type" :style="item.statisticsDimension=='merge-trend'&&'background-color:#6c5ce7;'" v-if="item.statisticsDimension=='merge-trend'">
                            {{ item.statisticsType=='daily'?'单日合并':
                            item.statisticsType=='weekly'?'7日合并':
                            item.statisticsType=='monthly'?'30日合并':'合并' }} 
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="chart-cover-mask">
                      <div class="chart-mask-title-name">
                        <span>{{ item.graphName }}</span>
                      </div>
                      <div class="chart-mask-title">
                        <span class="chart-mask-title-type" :style="item.statisticsDimension=='realtime'&&'background-color:#27ae60;'" v-if="item.statisticsDimension=='realtime'">实时</span>
                        <span class="chart-mask-title-type" :style="item.statisticsDimension=='total'&&'background-color:#409EFF;'" v-if="item.statisticsDimension=='total'">
                          {{ item.statisticsType=='daily'?'单日累计':
                          item.statisticsType=='weekly'?'7日累计':
                          item.statisticsType=='monthly'?'30日累计':'累计' }} 
                        </span>
                        <span class="chart-mask-title-type" :style="item.statisticsDimension=='trend'&&'background-color:#f39c12;'" v-if="item.statisticsDimension=='trend'">
                          {{ item.statisticsType=='daily'?'单日趋势':
                          item.statisticsType=='weekly'?'7日趋势':
                          item.statisticsType=='monthly'?'30日趋势':'趋势' }} 
                        </span>
                        <span class="chart-mask-title-type" :style="item.statisticsDimension=='contrast'&&'background-color:#e74c3c;'" v-if="item.statisticsDimension=='contrast'">
                          {{ item.statisticsType=='daily'?'单日对比':
                          item.statisticsType=='weekly'?'7日对比':
                          item.statisticsType=='monthly'?'30日对比':'对比' }} 
                        </span>
                        <span class="chart-mask-title-type" :style="item.statisticsDimension=='merge-trend'&&'background-color:#6c5ce7;'" v-if="item.statisticsDimension=='merge-trend'">
                          {{ item.statisticsType=='daily'?'单日合并':
                          item.statisticsType=='weekly'?'7日合并':
                          item.statisticsType=='monthly'?'30日合并':'合并' }} 
                        </span>
                      </div>
                      <div class="chart-mask-btn" @click="selectChart(item)">
                        <div class="chart-mask-btn-text">
                          <span>查看图表详情</span>
                          <i class="iconfont icon-tubiao-"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 点击指定图表卡片显示图表详情 -->
              <div class="big-chart-detail" v-else v-loading="bigChartDetailLoading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.7)">
                <!-- 实时 -->
                <div v-if="graphCode=='bar-realtime-1'">
                  <bar-realtime1 v-if="graphInfo.data" :datas="graphInfo"></bar-realtime1>
                  <empty v-else/>
                </div>
                <div v-if="graphCode=='bar-realtime-2'">
                  <bar-realtime2 v-if="graphInfo.data" :datas="graphInfo"></bar-realtime2>
                  <empty v-else/>
                </div>
                <div v-if="graphCode=='distribution-realtime-1'">
                  <distribution-realtime1 v-if="graphInfo.data" :datas="graphInfo" :graphDisFirstData="graphDisFirstData"></distribution-realtime1>
                  <empty v-else/>
                </div>
                <div v-if="graphCode=='distribution-realtime-2'">
                  <distribution-realtime2 v-if="graphInfo.data" :datas="graphInfo" :graphDisFirstData="graphDisFirstData"></distribution-realtime2>
                  <empty v-else/>
                </div>

                <!-- 总量 -->
                <div v-if="graphCode=='bar-total-1'">
                  <bar-total1 v-if="graphInfo.data" :datas="graphInfo"></bar-total1>
                  <empty v-else/>
                </div>
                <div v-if="graphCode=='pie-chart-total-1'">
                  <pie-chart-total1 v-if="graphInfo.data" :datas="graphInfo"></pie-chart-total1>
                  <empty v-else/>
                </div>
                
                <!-- 趋势 -->
                <div v-if="graphCode=='distribution-trend-1'">
                  <distribution-trend1 v-if="graphInfo.data" :datas="graphInfo"></distribution-trend1>
                  <empty v-else/>
                </div>
                <div v-if="graphCode=='distribution-trend-2'">
                  <distribution-trend2 v-if="graphInfo.data" :datas="graphInfo"></distribution-trend2>
                  <empty v-else/>
                </div>

                <!-- 对比 -->
                <div v-if="graphCode=='bar-contrast-1'">
                  <bar-contrast-1 v-if="graphInfo.data" :datas="graphInfo"></bar-contrast-1>
                  <empty v-else/>
                </div>
                <div v-if="graphCode=='bar-contrast-2'">
                  <bar-contrast-2 v-if="graphInfo.data" :datas="graphInfo"></bar-contrast-2>
                  <empty v-else/>
                </div>
                <div v-if="graphCode=='distribution-contrast-1'">
                  <distribution-contrast-1 v-if="graphInfo.data" :datas="graphInfo"></distribution-contrast-1>
                  <empty v-else/>
                </div>

                <!-- 合并趋势统计 -->
                <div v-if="graphCode=='distribution-merge-trend-1'">
                  <merge-trend1 v-if="graphInfo.data" :datas="graphInfo"></merge-trend1>
                  <empty v-else/>
                </div>

                <div class="close-chart" @click="closeChart">
                  <i class="iconfont icon-tubiao-"></i>
                  <span class="close-text">返回图表列表</span>
                </div>
              </div>
              
              <!--
              <box3/>
              <box4/>
              <box5/>
              <box6/>
              <box8/> -->
            </div>
            <!-- 没有图表列表 -->
            <div v-else>
              <empty/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import page from '@/components/page.vue'
  import dayjs from 'dayjs';
  // 图表模板卡片
  import legendBox1 from './chartLegend/legendBox1.vue'
  import legendBox2 from './chartLegend/legendBox2.vue'
  import legendBox3 from './chartLegend/legendBox3.vue'
  import legendBox4 from './chartLegend/legendBox4.vue'
  import legendBox5 from './chartLegend/legendBox5.vue'
  import legendBox6 from './chartLegend/legendBox6.vue'
  import legendBox7 from './chartLegend/legendBox7.vue'
  import legendBox8 from './chartLegend/legendBox8.vue'
  import legendBox9 from './chartLegend/legendBox9.vue'
  import legendBox10 from './chartLegend/legendBox10.vue'
  import legendBox11 from './chartLegend/legendBox11.vue'
  import legendBox12 from './chartLegend/legendBox12.vue'
  import legendBox13 from './chartLegend/legendBox13.vue'
  import legendBox14 from './chartLegend/legendBox14.vue'
  import legendBox15 from './chartLegend/legendBox15.vue'
  import legendBox16 from './chartLegend/legendBox16.vue'
  // 图表带数据
  import barRealtime1 from './chartIndex/realtime/barRealtime1'
  import barRealtime2 from './chartIndex/realtime/barRealtime2'
  import distributionRealtime1 from './chartIndex/realtime/distributionRealtime1'
  import distributionRealtime2 from './chartIndex/realtime/distributionRealtime2'
  
  import barTotal1 from './chartIndex/total/barTotal1'
  import pieChartTotal1 from './chartIndex/total/pieChartTotal1'

  import distributionTrend1 from './chartIndex/trend/distributionTrend1'
  import distributionTrend2 from './chartIndex/trend/distributionTrend2'

  import barContrast1 from './chartIndex/contrast/barContrast1'
  import barContrast2 from './chartIndex/contrast/barContrast2'
  import distributionContrast1 from './chartIndex/contrast/distributionContrast1'
  
  import mergeTrend1 from './chartIndex/mergeTrend/mergeTrend1.vue';

  import box3 from './chartIndex/box3'
  import box4 from './chartIndex/box4'
  import box5 from './chartIndex/box5'
  import box6 from './chartIndex/box6'
  import box8 from './chartIndex/box8'
  // 没有数据显示空组件
  import empty from './empty'
  let startTime = new Date(new Date().toLocaleDateString()).getTime(); // 当天0点
  var colorList = [
    '#5AF5EC','#9FE080','#FFDC60','#FF915A','#839be2',
    '#e8927d','#9BCA63','#fbe183','#F3A43B','#7ED3F4',
    '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
    '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
    '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
    '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66',
    
    '#5AF5EC','#9FE080','#fbe183','#FF915A','#839be2',
    '#e8927d','#9BCA63','#FAD860','#F3A43B','#7ED3F4',
    '#D7504B','#C6E579','#F4E001','#F0805A','#6ae2e2',
    '#FFB7DD','#f099ff','#FFCCCC','#FFC8B4','#cf80ff',
    '#FFFFBB','#FFAA33','#99FFFF','#CC00CC','#FF77FF',
    '#CC00CC','#C63300','#F4E001','#9955FF','#66FF66'
  ];
  export default {
    components: {
      page,
      legendBox1,
      legendBox2,
      legendBox3,
      legendBox4,
      legendBox5,
      legendBox6,
      legendBox7,
      legendBox8,
      legendBox9,
      legendBox10,
      legendBox11,
      legendBox12,
      legendBox13,
      legendBox14,
      legendBox15,
      legendBox16,

      barRealtime1,
      barRealtime2,
      distributionRealtime1,
      distributionRealtime2,

      barTotal1,
      pieChartTotal1,

      distributionTrend1,
      distributionTrend2,

      barContrast1,
      barContrast2,
      distributionContrast1,

      mergeTrend1,
      
      box3,
      box4,
      box5,
      box6,
      box8,
      empty,
    },
    data() {
      return {
        loading: false,
        treeLoading: false,
        chartListLoading: false,
        bigChartDetailLoading: false,
        size: 50,
        page: 1,
        total: 0,

        isCardList: false,
        configProject: [],
        current: null,
        graphId: null,
        graphCode: null,
        timer: null,

        graphList: [],//分类下图表列表
        graphInfo: {},//单个图表基本信息
        graphDetailData: [],//单个图表详情数据

        roleId: 0,
        count: 0,

        graphDisFirstData: [],
        stepTime: null,
        currentTime: new Date(),
        intervalTime: 0,
      }
    },
    methods: {
      dayjs,
      closeChart() {
        this.isCardList = false
        // 清空定时器
        clearInterval(this.timer)
      },
      // 选择指定图表
      async selectChart(item) {
        this.bigChartDetailLoading = true
        this.isCardList = true
        this.current = item.itemId
        this.graphId = item.graphId
        this.graphCode = item.graphCode
        await this.loadDataBasicInfo()
        // 清空定时器
        clearInterval(this.timer)
        // 如果是实时数据继续轮询数据接口
        if(this.graphCode=='bar-realtime-1'||this.graphCode=='bar-realtime-2') {//维度-实时数据-柱状
          await this.loadDataDetail()
          this.timer = setInterval(async ()=>{
            await this.loadDataDetail()
          },5000)
        }else if(this.graphCode=='distribution-realtime-1'||this.graphCode=='distribution-realtime-2') {//维度-实时数据-折线
          await this.loadDataDetail()
          await this.queryDistributionFirstData()
          this.timer = setInterval(async ()=>{
            await this.loadDataDetail()
          },5000)
        }else {//其他维度数据只请求一次
          await this.loadDataDetail()
        }
        this.$forceUpdate()
      },
      // 查询实时折线图第一次数据
      queryDistributionFirstData() {
        return new Promise((resolve,reject)=>{
          this.$ajax.post('dataModelGraphDailyReportQuery',{
            itemId: this.current,
            graphId: this.graphId
          }).then(res => {
            let arr = []
            this.reportData = res.data 
            this.reportData.forEach(async (v,i) => {
              let intervalTime = Number(((this.currentTime.getTime() - startTime) / v.values.length).toFixed(0))
              let obj = {
                name: v.eid,
                type: 'line',
                areaStyle: {
                  opacity: this.graphCode=='distribution-realtime-1' ? 0 : 0.1,
                },
                emphasis: {
                  focus: 'series'
                },
                itemStyle: {
                  color: colorList[i],
                  shadowColor: colorList[i],
                },
                lineStyle: {
                  shadowColor: colorList[i],
                  shadowBlur: 20,
                  color: {
                    type: 'linear',
                    colorStops: [
                      {offset:0,color:colorList[i]},
                      {offset:1,color:colorList[i]},
                    ]
                  }
                },
                smooth: this.graphCode=='distribution-realtime-1' ? false : true,
                showSymbol: false,
                data: []
              }
              let resData = await this.getData(v.values,intervalTime)
              obj.data = resData
              arr.push(obj)
            })
            this.graphDisFirstData = arr
            resolve()
          })
        })
      },
      getData(values,intervalTime) {
        return new Promise((resolve,reject)=>{
          let dArr = []
          let t
          let stepTime = startTime
          values.forEach(item => {
            if(parseFloat(item)<0) item = 0
            if(stepTime<=this.currentTime.getTime()) {
              t = new Date(stepTime).getTime()
              dArr.push({
                name: t,
                value: [t,item]
              });
              stepTime += intervalTime
            }
          })
          resolve(dArr)
        })
      },
      // 新增图表分类
      addConfigProject() {
        this.$router.push({ name: 'chartConfigAddClassify' })
      },
      // 编辑图表分类
      editConfigProject(row) {
        this.$router.push({ name: 'chartConfigEditClassify', params: row })
      },
      // 删除图表分类
      dltConfigProject() {

      },
      // 新增图表
      add() {
        this.$router.push({ name: 'chartConfigAdd' })
      },
      edit(row) {
        this.$router.push({ name: 'chartConfigEdit', params: {row: row}})
      },
      dlt(row) {
        this.$confirm("确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$ajax.post("alarmRulesDelete",{},{}, row.id).then((res) => {
              this.loadListData();
            });
        });
      },
      // 切换图表分类
      async configToggle(itemId) {
        this.current = itemId
        sessionStorage.setItem('itemId', itemId)
        this.isCardList = false
        // 清空定时器
        clearInterval(this.timer)
        await this.loadListData()
      },
      // 获取图表分类
      async loadConfigProject() {
        this.treeLoading = true
        this.$ajax.post('dataModelItemQuery',{}).then(async res => {
          this.configProject = res.data
          this.treeLoading = false
          if(sessionStorage.getItem('itemId')){
            this.current = sessionStorage.getItem('itemId')    
            await this.loadListData()
          }else{
            this.current = this.configProject[0].itemId
            sessionStorage.setItem('itemId', this.configProject[0].itemId)
            await this.loadListData()
          }
        }).catch(err=>{
          this.treeLoading = false
        })
      },
      // 查询分类下图表列表 
      loadListData() {
        return new Promise((resolve,reject)=>{
          this.chartListLoading = true
          this.$ajax.post('dataModelGraphListQuery',{
            itemId: this.current,
          }).then(res => {
            this.graphList = res.data
            this.chartListLoading = false
            resolve()
          }).catch(err =>{
            this.chartListLoading = false
          })
        })
      },
      // 查询单个图表详情基本信息
      loadDataBasicInfo() {
        return new Promise((resolve,reject)=>{
          this.$ajax.post('dataModelGraphInfoQuery',{
            itemId: this.current,
            graphId: this.graphId
          }).then(res => {
            this.graphInfo = res.data
            resolve()
          })
        })
      },
      // 查询单个图表详情数据
      loadDataDetail() {
        return new Promise((resolve,reject)=>{
          this.$ajax.post('dataModelItemByQuery',{
            itemId: this.current,
            graphId: this.graphId
          }).then(res => {
            res.data.forEach(v=>{
              if(parseFloat(v.value)<0) {
                v.value = 0
                return
              }
            })
            this.graphDetailData = res.data
            this.graphInfo.data = this.graphDetailData
            this.graphInfo = JSON.parse(JSON.stringify(this.graphInfo))
            this.bigChartDetailLoading = false
            resolve()
          }).catch(err => {
            this.bigChartDetailLoading = false
          })
        })
      },
      setSize() {
        let elMain = window.innerHeight
        let rTree = document.querySelector('.tree')
        if(rTree) rTree.style.height = elMain - 212 + 'px'
      },
    },
    mounted() { 
      this.loadConfigProject()
      this.staffId = sessionStorage.getItem('id')
      this.roleId = sessionStorage.getItem('r')
      let that = this
      that.setSize()
      window.addEventListener('resize', function() {
        that.setSize()
      }, false);
    },
    destroyed() {
      clearInterval(this.timer)
    }
  }
  </script>
  
  <style lang="less" scoped>
  .chart-btn {
    background: #2B82D9;
    border: 1px solid #2B82D9;
  }
  .chart-btn:focus, .chart-btn:hover {
    background: #5ba3ea;
    border: 1px solid #5ba3ea;
  }
  .chart-config-container {
    font-family: 'Alibaba-PuHuiTi-Regular';
    background-color: #F8F9FD;
    height: 97.6%;
  }
  .mains {
    padding: 400px 0;
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .el-dropdown-dis {
    color: #ccc;
    cursor: not-allowed;
  }
  .man-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: normal;
    padding: 0 0 5px;
    margin: 0;
  }
  .table-tree {
    display: flex;
    height: ~'calc(100% - 56px)';
    .tree {
      min-width: 300px;
      // border-right: 1px solid #2a2f3c;
      // margin-right: 0px;
      // background-color: #04102c;
      // border-top-left-radius: 20px;
      // border-bottom-left-radius: 20px;
      overflow: hidden;
      // padding: 0 10px;
    }
    .table {
      flex: 1;
    }
    .operate {
      &:hover {
        color: #409EFF;
      }
    }
    .custom-tree-node {
      font-size: 14px;
    }
  }
  .tree-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: #04102c;
    // color: #CCC;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    background: #F8F9FD;
    margin-bottom: 15px;
  }
  .tree-wrapper {
    overflow: auto;
    height: 90%;
    user-select: none;
  }
  .tree-park {
    font-size: 14px;
    cursor: pointer;
  }
  .park-title {
    line-height: 35px;
    margin: 5px 0;
    padding: 0 20px;
    border-radius: 4px;
    // color: #CCC;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tree-classify {
    line-height: 35px;
  }
  .classify-title {
    padding-left: 50px;
  }
  .class-icon {
    display: inline-block;
    margin-right: 8px;
  }
  .bright {
    background: #EBECF0;
    color: #2B82D9;
  }
  .sub-equipment-model {
    padding: 0 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .sub-empty {
    color: #909399;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    margin: 0 auto;
  }
  .equipment-item {
      width: 316px;
      box-shadow: 0px 0px 20px rgba(211, 211, 211, .5);
      border: 1px solid #ebeef5;
      border-radius: 15px;
      margin-right: 17px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
  }
  .i-pic {
    margin: 0 auto;
    width: 300px;
    height: 150px;
    border-radius: 5px;
    overflow: hidden;
    .i-pic-image {
      display: block;
      width: 100%;
      height: 100%;
      .i-pic-error {
        font-size: 100px;
        color: #d9ddde;
        padding: 30px 0;
        display: flex;
        justify-content: center;
      }
    }
  }
  .equipment-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    margin: 10px;
  }
  .equipment-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-top: 1px solid #efefef;
      padding-top: 10px;
      margin: 10px;
  }
  .chart-size {
    position: relative;
    .big-chart-detail {
      .close-chart {
        height: 30px;
        text-align: center;
        line-height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 0;
        z-index: 10;
        transition: all .3s ease;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        i {
          font-weight: bold;
        }
        &:hover {
          background: #e1f0ff;
          border: 1px solid #409EFF;
          color: #409EFF;
        }
      }
    }
  }
  .chart-cover {
    width: 100%;
    //height: 77vh;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    user-select: none;
    .chart-cover-item {
      width: 30%;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      background: rgba(0, 0, 0, 0);
      margin: 20px 40px;
      margin-right: 0;
      & > div {
        position: relative;
        .chart-top-title {
          width: 100%;
          height: 40px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
          display: flex;
          justify-content: center;
          font-size: 12px;
          transition: all .5s ease;
          .chart-top-title-shape-wrap {
            width: 60%;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #1d2a44;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            .chart-top-title-shape-left {
              color: #FFF;
            }
            .chart-top-title-shape-right {
              .chart-top-title-type {
                background: #409EFF;
                color: #FFF;
                padding: 4px 15px;
                border-radius: 4px;
                font-size: 10px;
                line-height: 40px;
              }
            }
          }
        }
        .chart-cover-mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
          transition: all .3s ease;
          color: #FFF;
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .chart-mask-title-name {
            font-size: 20px;
            font-weight: bold;
            display: none;
          }
          .chart-mask-title {
            margin: 10px 0;
            display: none;
            .chart-mask-title-type {
              background: #409EFF;
              padding: 4px 15px;
              border-radius: 4px;
              font-size: 12px;
              line-height: 40px;
            }
          }
          .chart-mask-btn {
            display: none;
            background: rgba(0, 0, 0, 0.4);
            border: 1px solid #409EFF;
            height: 40px;
            line-height: 40px;
            border-radius: 6px;
            width: 180px;
            text-align: center;
            color: #409EFF;
            transition: all .3s ease;
            .chart-mask-btn-text {
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                display: inline-block;
                margin-left: 20px;
              }
              i {
                display: inline-block;
                width: 40px;
                height: 40px;
                transition: all .3s ease;
                transform: rotate(180deg) translate(0px);
              }
            }
            &:hover {
              color: #FFF;
              background-color: #409EFF;
              border: 1px solid #409EFF;
              cursor: pointer;
            }
            &:hover i {
              transform: rotate(180deg) translate(-10px);
            }
          }
        }
      }
    }
    .chart-cover-item > div:hover .chart-cover-mask {
      background: rgba(0, 0, 0, 0.8);
    }
    .chart-cover-item > div:hover .chart-mask-title-name, 
    .chart-cover-item > div:hover .chart-mask-title, 
    .chart-cover-item > div:hover .chart-mask-btn {
      display: block;
    }
    .chart-cover-item > div:hover .chart-top-title {
      top: -40px;
    }
  }
  
  </style>