import { render, staticRenderFns } from "./distributionTrend2.vue?vue&type=template&id=37f7bdee&scoped=true&"
import script from "./distributionTrend2.vue?vue&type=script&lang=js&"
export * from "./distributionTrend2.vue?vue&type=script&lang=js&"
import style0 from "./distributionTrend2.vue?vue&type=style&index=0&id=37f7bdee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f7bdee",
  null
  
)

export default component.exports