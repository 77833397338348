<template>
    <div class="box-wrapper" v-loading="loading" 
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中"
      element-loading-background="rgba(0, 0, 0, 0.1)">
      <div class="content">
        <div class="box-title">
          <span>{{ datas.graphName }}</span>
          <div class="title-badge">
            <span class="badge" v-if="datas.statisticsDimension=='total'" :style="datas.statisticsDimension=='total'&&'background-color:#409EFF;'">
              {{ datas.statisticsType=='daily'?'单日累计':
              datas.statisticsType=='weekly'?'7日累计':
              datas.statisticsType=='monthly'?'30日累计':'累计' }} 
            </span>
          </div>
        </div>
        <div id="piecharttotal1" class="pie-chart-total1" :style="'height:'+height+'px;'"></div>
        <!-- <img class="chart-title" src="../../img/chart_title.png" alt="">
        <img class="chart-bg" src="../../img/chart_bg.png" alt=""> -->
      </div>
    </div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  import dayjs from "dayjs"
  export default {
    props: {
      datas: {
        type: Object
      }
    },
    data() {
      return {
        loading: false,
        data: [],
        height: 600,
      };
    },
    watch: {
      datas: {
        handler (newVal, oldVal) {
          this.datas = newVal
          this.data = this.datas.data
          let that = this
          that.setSize()
          window.addEventListener('resize', function() {
            that.setSize()
          }, false);
          newVal && newVal.length!=0 && this.setChart()
        }
      }
    },
    methods: {
      dayjs,
      setChart() {
        var myChart = echarts.init(document.getElementById("piecharttotal1"));
        var option = {
          title: {
            subtext: '-',
            top: -8,
            left: 18
          },
          backgroundColor: '',
          tooltip: {
            trigger: "item"
          },
          legend: {
            y: 'top',
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          series: [
            {
              name: '上月',
              type: 'pie',
              radius: '50%',
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              data: []
            }
          ],
        };
        // let xAxisData = []
        // this.data.dataTrends.forEach(v => {
        //   xAxisData.push(v.date)
        // })
        // let x = []
        // for(var i = 0; i < xAxisData.length; i++){
        //   if(x.indexOf(xAxisData[i]) == -1){
        //     x.push(xAxisData[i]);
        //   }
        // }
        // x.sort()
        // option.xAxis.data = x;
  
        // this.data.dataTrends.forEach(v => {
        //   option.series[0].data.push(v.value)
        // })
        option.title.subtext = '单位: '+(this.datas?this.datas.unitAttribute:'')
        option.series[0].name = this.datas?this.datas.elemental1Attribute:''
        this.data.forEach(item => {
          option.series[0].data.push({
            name: item.equipmentName,
            value: item.value?parseFloat(item.value):0,
          })
        });
        myChart.setOption(option);
        setTimeout(()=>{
          window.onresize = function () {
            myChart.resize();
          }
          window.addEventListener('keydown', function(event) {
            if(event.keyCode===122) {
              myChart.resize();
            }
          })
        },0)
      },
      setSize() {
        let elMain = window.innerHeight
        let rBox = document.querySelector('.box-wrapper')
        let rMain = document.querySelector('.bar-total')
        if(rBox) rBox.style.height = elMain - 212 + 'px'
        if(rMain) rMain.style.height = elMain - 312 + 'px'
      },
    },
    mounted() {
      this.data = this.datas.data
      this.setChart()
      let that = this
      that.setSize()
      window.addEventListener('resize', function() {
        that.setSize()
      }, false);
    },
  };
  </script>
  
  <style lang="less" scoped>
  .pie-chart-total1 {
    width: 100%;
    height: 200px;
    margin: 20px auto 0;
    z-index: 2;
  }
  .box-title {
    // color: #FFF;
    font-size: 20px;
    padding: 0 16px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    top: 6px;
    left: 6px;
    z-index: 1;
    .title-badge {
      margin-left: 20px;
      .badge {
        background: #409EFF;
        color: #FFF;
        padding: 2px 10px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 50px;
        font-weight: normal;
      }
    }
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 40px;
  }
  .box-wrapper {
    overflow: hidden;
    // background-color: #04102c;
    position: relative;
    // border-radius: 10px;
    // height: 831px;
    .chart-title {
      width: 93%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 18px auto 0;
      z-index: 0;
    }
    .chart-bg {
      width: 98%;
      height: 95%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 0;
    }
    .content {
      border-radius: 10px;
      padding-bottom: 16px;
    }
    .date {
      display: flex;
      position: absolute;
      right: 10%;
      top: 0;
      z-index: 10;
      .date-text {
        font-size: 12px;
        font-weight: normal;
        color: #FFF;//#767779
        margin-right: 10px;
        user-select: none;
      }
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
  /deep/.el-form-item__content {
    line-height: 50px;
  }
  /deep/.el-form-item__label {
    font-size: 12px;
    font-weight: normal;
    color: #FFF;//#767779
  }
  </style>